/* eslint-disable no-console */
import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import getConfig from 'next/config';

import { Head } from '@/components';
import { HomepageRenderer } from '@/components/SectionListRenderer';
import { CONNECTING_TO_CONTENTFUL, CONNECTING_TO_REDIS, REDIS_HOMEPAGE_KEY, ThunkDebugName } from '@/constants';
import { getAllEventThumbnails, getAllThumbnails, getLandingPage } from '@/fetchers';
import { utils_getRightSectionList } from '@/fetchers/utils';
import type { ILandingPage } from '@/interfaces';
import { checkIsPreviewModeRequest } from '@/utils';
import createServerSidePropsResult, { type IThunk } from '@/utils/createServerSidePropsResult.utils';

const { serverRuntimeConfig } = getConfig();

const Home: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({ homepageData, webVersion }) => {
  // biome-ignore lint/suspicious/noConsoleLog: <explanation>
  console.log(webVersion);

  return (
    <>
      {homepageData?.seo && <Head {...homepageData.seo} />}
      <HomepageRenderer sectionList={homepageData?.sectionList} />
    </>
  );
};

interface IHomepageServerSideProps {
  homepageData: ILandingPage | null;
  webVersion: string;
}

type Thunk = IThunk<IHomepageServerSideProps>;

const getRedisUtils: Thunk = async ({ nextJsContext, customContext }) => {
  if (!customContext.serverRuntimeConfig.useDev) {
    const redisUtils = await import('@/utils/redis.utils');

    customContext.getServerPropsCached = redisUtils.getServerPropsCached;
    customContext.cacheServerProps = redisUtils.cacheServerProps;
    customContext.setUrlById = redisUtils.setUrlById;
  }

  nextJsContext.res.setHeader('Cache-Control', 'public, s-maxage=43200, stale-while-revalidate=60');
};

getRedisUtils.debugName = ThunkDebugName.GetRedisUtils;

const getServerPropsCached: Thunk = async ({ customContext }) => {
  const serverPropsCached: IHomepageServerSideProps | null = JSON.parse(
    (await customContext.getServerPropsCached(REDIS_HOMEPAGE_KEY)) || 'null',
  );

  if (serverPropsCached) {
    return {
      props: {
        ...serverPropsCached,
        webVersion: CONNECTING_TO_REDIS,
      },
    };
  }
};

getServerPropsCached.debugName = ThunkDebugName.GetServerPropsCached;

const getHomepageData: Thunk = async ({ nextJsContext, customContext }) => {
  const { serverSideProps } = customContext;

  const isPreviewMode = checkIsPreviewModeRequest(nextJsContext.query);

  const [allEvents, allArticles]: any = await Promise.all([
    getAllEventThumbnails({ isPreviewMode, slug: 'homepage' }),
    getAllThumbnails({ isPreviewMode, slug: 'homepage' }),
  ]);

  serverSideProps.homepageData = await getLandingPage({
    isPreviewMode,
    slug: '',
    allEventsList: allEvents,
    allArticlesList: allArticles,
  });

  if (!isPreviewMode && serverSideProps.homepageData) {
    serverSideProps.homepageData.rightSectionList = utils_getRightSectionList({
      articles: allArticles,
      events: allEvents,
      data: serverSideProps.homepageData.rightSectionList,
    });

    customContext.setUrlById(serverSideProps.homepageData.sys.id, REDIS_HOMEPAGE_KEY);
    customContext.cacheServerProps(REDIS_HOMEPAGE_KEY, serverSideProps);
  }

  return {
    props: {
      ...serverSideProps,
    },
  };
};

getHomepageData.debugName = 'GET_HOMEPAGE_DATA';

export const getServerSideProps: GetServerSideProps<IHomepageServerSideProps> = async (context) => {
  return createServerSidePropsResult<IHomepageServerSideProps>({
    context,
    customContext: {
      serverSideProps: {
        homepageData: null,
        webVersion: CONNECTING_TO_CONTENTFUL,
      },
      serverRuntimeConfig,
      getServerPropsCached: () => null,
      cacheServerProps: () => null,
      setUrlById: () => null,
    },
    thunks: [getRedisUtils, getServerPropsCached, getHomepageData],
  });
};

export default Home;
